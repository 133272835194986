import { PopCcontainer, PopupTitle } from '../Css/Pop';
import Modal from '../Modal';
import { Tit } from '../Titles';
import Button, { BtnClose } from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import InputRadio from '@/components/Input/InputRadio';
import {
  NotiBox,
  NotiList,
  NotiItem,
  NotiTitle,
  NotiParagraph,
} from '@/components/Text/TextNoti';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, useCallback, useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

export interface ModalFormProps {
  isAgreeYn: string;
}

export interface ModalProps {
  isOpen?: boolean;
  isAgree?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 창닫기
  onRequestClose: () => void;
  // 저장
  onSubmit: (data: ModalFormProps) => void;
}

const PopupContent = styled.div`
  padding: 40px 0;

  br {
    &.br_m {
      display: none;
    }
    ${breakpoint(`mobile`)} {
      &.br_pc {
        display: none;
      }
      &.br_m {
        display: block;
      }
    }
  }
  ${NotiParagraph}, ${NotiItem} {
    font-size: 16px;
    font-weight: 400;
    &::after {
      background: #2d2926;
    }
  }
  ${NotiBox} {
    background: #f0f7fa;
    padding: 25px 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    ${NotiTitle} {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    ${NotiParagraph} {
      font-size: 16px;
      color: #1cabe2;
      margin-top: 30px;
    }
  }
  .agree_wrap {
    max-width: 235px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .btn-wrap {
    text-align: center;
    margin-top: 40px;
    ${Button} {
      width: 252px;
      height: 60px;
      border-radius: 30px;
    }
  }
  ${ErrorMessage} {
    text-align: center;
  }

  ${breakpoint(`mobile`)} {
    padding: 30px 0;
    ${NotiParagraph}, ${NotiItem} {
      font-size: 14px;
    }
    ${NotiItem} + ${NotiItem} {
      margin-top: 0;
    }
    ${NotiBox} {
      margin: 30px 0;
      padding: 20px 15px;
      ${NotiTitle} {
        font-size: 15px;
        margin-bottom: 15px;
      }
      ${NotiParagraph} {
        font-size: 14px;
        margin-top: 20px;
      }
    }
    .btn-wrap {
      margin-top: 30px;
      ${Button} {
        width: 100%;
        max-width: 280px;
        height: 45px;
        border-radius: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
`;

// eslint-disable-next-line arrow-body-style
const OnePercentLayerPopup: FC<ModalProps> = ({
  onRequestClose,
  onSubmit,
  ...props
}) => {
  const [agreeCheck, setAgreeCheck] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ModalFormProps>({
    defaultValues: {
      isAgreeYn: ``,
    },
  });
  const onSubmitForm: SubmitHandler<ModalFormProps> = useCallback(
    (formData) => {
      onSubmit(formData);
    },
    [onSubmit],
  );
  useEffect(() => {
    setAgreeCheck(props.isAgree || false);
  }, [props.isAgree]);

  return (
    <Modal onRequestClose={onRequestClose} maxWidth="880px" {...props}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <PopCcontainer>
          <PopupTitle>
            <Tit size="s3" color="sky">
              개인정보 수집 및 이용 안내
            </Tit>
            <BtnClose onClick={() => onRequestClose()}>
              <span className="a11y">닫기</span>
            </BtnClose>
          </PopupTitle>
          <PopupContent>
            <NotiParagraph>
              유니세프 한국위원회는 ‘1%의 기적 캠페인’의 <br className="br_m" />
              원활한 진행을 위하여 귀하의 개인정보를 <br className="br_m" />
              아래와 같이 수집·이용합니다.
              <br />
              <br />
              아래 내용을 충분히 읽어 보신 후, <br className="br_m" />
              동의 여부를 선택하여 주시기 바랍니다.
            </NotiParagraph>
            <NotiBox>
              <NotiTitle>개인정보 수집 및 이용 동의</NotiTitle>
              <NotiList>
                <NotiItem>
                  수집목적: 1%의 기적 캠페인 관리, 신청 안내 및 문의 응대
                </NotiItem>
                <NotiItem>수집항목: 담당자명, 연락처, 이메일</NotiItem>
                <NotiItem>보유기간: 처리정지 요청시까지</NotiItem>
              </NotiList>
              <NotiParagraph>
                ※ 동의를 거부할 수 있으며, 이를 거부하는 경우 신청이 제한될 수
                있습니다.
              </NotiParagraph>
            </NotiBox>

            <div className="agree_wrap">
              <InputRadio
                label="동의함"
                value="Y"
                name="isAgreeYn"
                onClick={() => {
                  setAgreeCheck(true);
                }}
                ref={register({
                  required: {
                    value: true,
                    message: `개인정보 수집 및 이용 동의 여부를 선택해주세요.`,
                  },
                })}
                checked={agreeCheck}
              />
              <InputRadio
                label="동의하지 않음"
                name="isAgreeYn"
                value="N"
                onClick={() => {
                  setAgreeCheck(false);
                }}
                ref={register({
                  required: {
                    value: true,
                    message: `개인정보 수집 및 이용 동의 여부를 선택해주세요.`,
                  },
                })}
                checked={!agreeCheck}
              />
            </div>
            {errors.isAgreeYn && (
              <ErrorMessage>{errors.isAgreeYn.message}</ErrorMessage>
            )}

            <div className="btn-wrap">
              <Button type="submit">확인</Button>
            </div>
          </PopupContent>
        </PopCcontainer>
      </form>
    </Modal>
  );
};

export default OnePercentLayerPopup;
